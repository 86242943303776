import { HttpClient } from '../../httpClient'

export enum InviteAction {
	ACCEPT = 'accept',
	REJECT = 'reject',
}

export interface IInviteAction {
	userId?: string
	firstName?: string
	lastName?: string
	password?: string
	invitationId: string
	action: InviteAction
}

export const updateOrganizationInvite = async (client: HttpClient, inviteId: string, inviteAction: IInviteAction) => {
	const response = await client.post(`/api/invites/${inviteId}/action`, inviteAction)
	return response.data
}
