import React from 'react'
import DefaultLayout from '../../layout/DefaultLayout.tsx'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import {
	AssessmentsWithinUserPositionQuery,
	AssessmentWithResponsesQuery,
	useAssessmentsWithinUserPositionQuery,
	useAssessmentWithResponsesQuery,
} from '../../generated/graphql.ts'
import { RadarComponent } from '../../components/Charts/Radar.tsx'
import PeriodAssessmentsChart from '../../components/Charts/PeriodAssessmentsChart.tsx'
import Loader from '../../common/Loader'
import { getMe } from '../../MeProvider.ts'
import OverviewCard from './OverviewCard.tsx'
import { generateChartData, snakeToWords } from './utils.ts'
import dayjs from 'dayjs'

const PositionAssessmentDisplay = ({
	assessments,
}: {
	assessments: AssessmentsWithinUserPositionQuery['assessmentsWithinUserPosition']
}) => {
	const mappedData = assessments?.reduce(
		(acc, assessment) => {
			if (assessment) {
				const timestamp = dayjs(assessment.managerResponse!.completedAt).valueOf()
				acc.manager.push([timestamp, assessment.managerResponse!.averageScore!])
				acc.employee.push([timestamp, assessment.userResponse!.averageScore!])
			}

			return acc
		},
		{
			manager: [] as number[][],
			employee: [] as number[][],
		}
	)

	return (
		<PeriodAssessmentsChart
			series={[
				{ name: 'Manager eval %', data: mappedData!.manager },
				{
					name: 'Personal eval %',
					data: mappedData!.employee,
				},
			]}
		/>
	)
}

const PositionAssessments = ({ data, isLoading }: { data?: AssessmentsWithinUserPositionQuery; isLoading: boolean }) => {
	const dataExists = Boolean(data?.assessmentsWithinUserPosition?.length)

	return (
		<div className="col-span-12 rounded-2xl bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
			<div className="mb-4 justify-between gap-4 sm:flex">
				<div>
					<h4 className="text-xl font-semibold text-black dark:text-white">Position progress</h4>
				</div>
			</div>
			<div style={{ minHeight: '414px' }} className={!dataExists ? 'flex justify-center items-center' : ''}>
				{isLoading && <Loader />}
				{!isLoading && dataExists && <PositionAssessmentDisplay assessments={data!.assessmentsWithinUserPosition} />}
				{!isLoading && !dataExists && <p>No data yet</p>}
			</div>
		</div>
	)
}

const LatestAssessmentGraph = ({ data }: { data: AssessmentWithResponsesQuery['assessment'] }) => {
	const chartData = generateChartData(data)

	return <RadarComponent chartData={chartData} />
}

const LatestAssesment = ({ data: allAssessments }: { data?: AssessmentsWithinUserPositionQuery; isLoading: boolean }) => {
	const graphQlClient = useGraphQLClient()

	const { data, isLoading } = useAssessmentWithResponsesQuery(
		graphQlClient,
		{ assessmentId: allAssessments?.assessmentsWithinUserPosition?.[0]?.id! },
		{
			enabled: !!allAssessments?.assessmentsWithinUserPosition?.[0]?.id,
		}
	)

	return (
		<div className="rounded-2xl bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark col-span-12 xl:col-span-4">
			<div className="mb-4 justify-between gap-4 sm:flex">
				<div>
					<h4 className="text-xl font-semibold text-black dark:text-white">Current evaluation</h4>
				</div>
			</div>
			<div style={{ minHeight: '404px' }} className="flex justify-center items-center">
				{isLoading && <Loader />}
				{!isLoading && data && <LatestAssessmentGraph data={data.assessment} />}
				{!isLoading && !data && <p>No data</p>}
			</div>
		</div>
	)
	// return <RadarComponent chartData={chartData}/>
}

const HomePage: React.FC = () => {
	const me = getMe()
	const graphQlClient = useGraphQLClient()
	const { data, isLoading } = useAssessmentsWithinUserPositionQuery(graphQlClient, { latest: false, isCompleted: true })

	return (
		<DefaultLayout>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
				{
					<OverviewCard
						title={me?.userPosition?.position.role ?? 'Unknown position'}
						total={me?.userPosition?.position.level ?? 'Unknown level'}
					>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C50E0" className="size-6">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
							/>
						</svg>
					</OverviewCard>
				}
				<OverviewCard
					title="Current career path"
					total={me?.userPosition?.positionTrajectory.path ? snakeToWords(me.userPosition.positionTrajectory.path) : 'Unknown path'}
					span={2}
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C50E0" className="size-6">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
						/>
					</svg>
				</OverviewCard>
				<OverviewCard
					title="At position since"
					total={me.userPosition?.sinceDate ? dayjs(me.userPosition?.sinceDate).format('DD MMM YYYY') : ''}
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C50E0" className="size-6">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
						/>
					</svg>
				</OverviewCard>
			</div>
			<div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
				<PositionAssessments data={data} isLoading={isLoading} />
				<LatestAssesment data={data} isLoading={isLoading} />
			</div>
		</DefaultLayout>
	)
}

export default HomePage
