import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import './css/style.css'
import './css/satoshi.css'
import 'jsvectormap/dist/css/jsvectormap.css'
import 'flatpickr/dist/flatpickr.min.css'
import { LogtoConfig, LogtoProvider, UserScope } from '@logto/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Authenticator from './Authenticator.tsx'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

/*
Sentry.init({
	//live dsn
	dsn: 'https://6cd72a0b4daf14457c33361b21e07227@o4507647513788416.ingest.de.sentry.io/4507667799933008',
	// local dsn
	//dsn: 'https://0209f2c45390258f5c2745d2c1b3e75b@o4507647513788416.ingest.de.sentry.io/4507647542689872',
	//backend routes
	//dsn: "https://74e3dd9898c4c668aeb6e5a716ba97de@o4507647513788416.ingest.de.sentry.io/4507706874069072",
	integrations: [Sentry.browserTracingIntegration({ tracingOrigins: ["*"] }), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [/^https:\/\/app\.nextpeak\.work/],
	//tracePropagationTargets: [/^https:\/\/backend-routes\.nextpeak-app\.pages\.dev/],
	//tracePropagationTargets: [/^http:\/\/localhost(:\d+)?/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
*/

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
})

function Root() {
	const config: LogtoConfig = {
		endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
		appId: import.meta.env.VITE_LOGTO_APP_ID,
		scopes: [
			'all',
			'read:data',
			'delete:member',
			'delete:data',
			'manage:member',
			'invite:member',
			'write:data',
			'openid',
			'profile',
			'email',
			UserScope.Email,
			UserScope.Phone,
			UserScope.CustomData,
			UserScope.Identities,
			UserScope.Organizations,
			UserScope.Roles,
			//'organization:roles',
			UserScope.Organizations,
			UserScope.OrganizationRoles,
			'urn:logto:scope:organization_token',
		],
		resources: [import.meta.env.VITE_LOGTO_API_URL],
	}

	return (
		<React.StrictMode>
			<LogtoProvider config={config}>
				<QueryClientProvider client={queryClient}>
					<Router>
						<Authenticator />
					</Router>
					<ToastContainer />
				</QueryClientProvider>
			</LogtoProvider>
		</React.StrictMode>
	)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Root />)
