import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import OrganizationChart from '../../pages/Organization/OrganizationChart'
import Information from '../../pages/Organization/Information'

const OrganizationSettings = () => {
	return (
		<DefaultLayout>
			<Breadcrumb pageName="Organization / Settings" />
			<Information />
		</DefaultLayout>
	)
}

export default OrganizationSettings
