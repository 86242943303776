import { AssessmentWithResponsesQuery } from '../../generated/graphql.ts'

export const snakeToWords = (str: string) => {
	// Split the string by underscore
	let words = str.split('_')

	// Capitalize the first word and keep the rest in lowercase
	words = words.map((word, index) => {
		return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()
	})

	// Join the words back with spaces
	return words.join(' ')
}

export function generateChartData(assessment: AssessmentWithResponsesQuery['assessment']) {
	const userResponseId = assessment.userResponse?.id
	const managerResponseId = assessment.managerResponse?.id

	const userResponse = assessment.assessmentResponses.find((response) => response.id === userResponseId)
	const managerResponse = assessment.assessmentResponses.find((response) => response.id === managerResponseId)

	// Assuming both user and manager have the same categories structure
	return userResponse?.averageByCategory.map((userCategory) => {
		const managerCategory = managerResponse?.averageByCategory.find((managerCategory) => managerCategory.category === userCategory.category)

		return {
			category: userCategory.category,
			user: userCategory.average,
			manager: managerCategory?.average,
		}
	})
}
