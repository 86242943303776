import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { Assessment, UserAssessmentsQuery, useUserAssessmentsQuery } from '../../generated/graphql.ts'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import Loader from '../../common/Loader'
import { Link } from 'react-router-dom'
import Label from '../../components/Label.tsx'
import { isAssessmentFilledOutByManager, isAssessmentFilledOutByUser } from '../../utils.ts'
import { getAssessmentResponseByUserId } from './utils.ts'
import EmptyTableRow from '../../components/EmptyTableRow.tsx'
import { getMe } from '../../MeProvider.ts'
import { useEffect, useState } from 'react'

interface AssessmentRowProps {
	assessment: Assessment
	userId: string
}

const AssessmentRow = ({ assessment, userId }: AssessmentRowProps) => {
	const getAssessmentFilledStatusLabel = (assessment: Assessment) => {
		if (isAssessmentFilledOutByUser(assessment)) {
			if (isAssessmentFilledOutByManager(assessment)) {
				return <Label type="success">Completed</Label>
			}
			return <Label type="warning">Waiting for manager</Label>
		}
		if (isAssessmentFilledOutByManager(assessment)) {
			return <Label type="warning">Waiting for employee</Label>
		}
		return <Label type="info">Pending</Label>
	}

	const myResponse = getAssessmentResponseByUserId(assessment, userId!)

	const getActionLink = () => {
		if (!myResponse || myResponse?.completedAt) {
			return `/assessments/${assessment.id}`
		}

		return `/assessments/${assessment.id}/responses/${myResponse.id}`
	}

	return (
		<tr>
			<td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
				<h5 className="font-medium text-black dark:text-white">{assessment.user.firstName + ' ' + assessment.user.lastName}</h5>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<p className="text-black dark:text-white">{assessment.assessmentType.position.title}</p>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<p className="text-black dark:text-white">{assessment.assessmentType.position.level}</p>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				{getAssessmentFilledStatusLabel(assessment as Assessment)}
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<Link className="hover:text-primary" to={getActionLink()}>
					<svg className="fill-current" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
							fill=""
						/>
						<path
							d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
							fill=""
						/>
					</svg>
				</Link>
			</td>
		</tr>
	)
}

export const AssessmentsTable = ({ assessments }: { assessments: UserAssessmentsQuery['userAssessments'] }) => {
	const me = getMe()

	return (
		<div className="rounded-2xl bg-white shadow-default dark:border-strokedark dark:bg-boxdark my-3 overflow-hidden">
			<div className="max-w-full overflow-x-auto">
				<table className="w-full table-auto">
					<thead>
						<tr className="bg-gray-2 text-left dark:bg-meta-4">
							<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">Name</th>
							<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white">Title</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Position</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Status</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Action</th>
						</tr>
					</thead>
					<tbody>
						{!!assessments.length &&
							assessments.map((assessment) => <AssessmentRow assessment={assessment as Assessment} key={assessment.id} userId={me.id!} />)}
						{!assessments.length && <EmptyTableRow emptyText="No assessments found" />}
					</tbody>
				</table>
			</div>
		</div>
	)
}

interface TabbedTablesViewProps {
	employeeAssessments: UserAssessmentsQuery['userAssessments']
	managerAssessments: UserAssessmentsQuery['userAssessments']
}

const TabbedTablesView = ({ employeeAssessments, managerAssessments }: TabbedTablesViewProps) => {
	const [openTab, setOpenTab] = useState(1)

	useEffect(() => {
		if (!employeeAssessments.length && managerAssessments.length) {
			setOpenTab(2)
			console.log('Pls no loop')
		}
	}, [])

	const activeClasses = 'text-primary border-primary'
	const inactiveClasses = 'border-transparent'

	return (
		<div>
			<div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
				<Link
					to="#"
					className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
						openTab === 1 ? activeClasses : inactiveClasses
					}`}
					onClick={() => setOpenTab(1)}
				>
					Personal
				</Link>
				{!!managerAssessments.length && (
					<Link
						to="#"
						className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
							openTab === 2 ? activeClasses : inactiveClasses
						}`}
						onClick={() => setOpenTab(2)}
					>
						Subordinates
					</Link>
				)}
			</div>
			<div>
				<div className={`${openTab === 1 ? 'block' : 'hidden'}`}>
					<AssessmentsTable assessments={employeeAssessments} />
				</div>
				<div className={`${openTab === 2 ? 'block' : 'hidden'}`}>
					<AssessmentsTable assessments={managerAssessments} />
				</div>
			</div>
		</div>
	)
}

const Assessments = () => {
	const me = getMe()
	const graphQlClient = useGraphQLClient()
	const { data: employeeAssessments, isLoading: isEmpLoading } = useUserAssessmentsQuery(graphQlClient, {
		userId: me.id!, // TODO: should be type checked to non-falsy,
		userIsManager: false,
	})

	const { data: managerAssessments, isLoading: isManLoading } = useUserAssessmentsQuery(graphQlClient, {
		userId: me.id!, // TODO: should be type checked to non-falsy,
		userIsManager: true,
	})

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Assessments" />
			<div className="flex flex-col gap-10">
				{(isEmpLoading || isManLoading) && <Loader />}
				{!isEmpLoading && !isManLoading && employeeAssessments && managerAssessments && (
					<TabbedTablesView
						employeeAssessments={employeeAssessments.userAssessments}
						managerAssessments={managerAssessments.userAssessments}
					/>
				)}
			</div>
		</DefaultLayout>
	)
}

export default Assessments
