import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import { Assessment, useAssessmentQuery, useCompleteAssessmentResponseMutation, useSaveAnswersMutation } from '../../generated/graphql.ts'
import { mapData, SurveyJson } from '../Surveys/Surveys.tsx'
import 'survey-core/defaultV2.min.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { useCallback, useState } from 'react'
import { DefaultLight } from 'survey-core/themes'
import { useQueryClient } from '@tanstack/react-query'
import Loader from '../../common/Loader'
import { getMe } from '../../MeProvider.ts'
import { getAssessmentResponseByUserId } from './utils.ts'

interface AssessmentSurveyProps {
	assessmentId: string
	responseId: string
	data: SurveyJson
}

const SurveyLayer = ({ data, assessmentId, responseId }: AssessmentSurveyProps) => {
	const graphQlClient = useGraphQLClient()
	const queryClient = useQueryClient()
	const mutation = useSaveAnswersMutation(graphQlClient)
	const completeMutation = useCompleteAssessmentResponseMutation(graphQlClient)
	const navigation = useNavigate()
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

	const survey = new Model(data)
	const surveyComplete = useCallback(async (sender: any) => {
		try {
			setIsSubmitting(true)
			const payload = {
				assessmentId,
				answers: Object.entries(sender.data).map(([questionId, answer]) => ({
					questionId: questionId,
					answerValue: answer as number,
				})),
			}
			await mutation.mutateAsync(payload)
			await completeMutation.mutateAsync({ assessmentResponseId: responseId })
			await queryClient.invalidateQueries({ queryKey: ['UserAssessments'] })
			navigation('/assessments') // This is wrong, should be redirected to the assessment page we came from
		} catch (e) {
			console.log(e)
		} finally {
			setIsSubmitting(false)
		}
	}, [])

	survey.onComplete.add(surveyComplete)
	survey.applyTheme(DefaultLight)

	if (isSubmitting) {
		return <Loader />
	}

	return <Survey model={survey} />
}

const AssessmentSurvey = () => {
	const { assessmentId, responseId } = useParams()

	if (!assessmentId || !responseId || assessmentId === 'undefined' || responseId === 'undefined') {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	const { id } = getMe()
	const graphQlClient = useGraphQLClient()
	const { data } = useAssessmentQuery(graphQlClient, { assessmentId })

	if (!data) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	const myResponse = getAssessmentResponseByUserId(data.assessment as Assessment, id!)

	if (!myResponse) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>Not allowed to be here</div>
				</div>
			</DefaultLayout>
		)
	}

	if (myResponse.completedAt) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>You have already answered this assessment</div>
				</div>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Assessment" />
			<div className="flex flex-col gap-10">
				<SurveyLayer data={mapData(data.assessment.assessmentType.questions)} assessmentId={assessmentId} responseId={responseId!} />
			</div>
		</DefaultLayout>
	)
}

export default AssessmentSurvey
