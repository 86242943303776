import { useMemo } from 'react'
import { NEXTPEAK_API_URL } from './config'
import { useAuth } from './AuthProvider.ts'
import { useOrgAuth } from './OrgAuthProvider.ts'
import { NextpeakClient } from './services/nextpeakClient'
import { HttpClientFactory } from './services/httpClient'

export interface IRestClientOpts {
	withoutToken?: boolean
}

export const useRestClient = ({ withoutToken }: IRestClientOpts = {}) => {
	const { token: clientToken } = useAuth()
	const { token: orgToken } = useOrgAuth()
	return useMemo(() => {
		const apiToken = orgToken ?? clientToken

		if (!apiToken && !withoutToken) {
			throw new Error('No token found')
		}

		return new NextpeakClient({ baseUrl: NEXTPEAK_API_URL, apiToken: !withoutToken ? apiToken : undefined }, new HttpClientFactory())
	}, [orgToken, clientToken])
}
