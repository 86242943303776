import { useMutation, useQuery } from '@tanstack/react-query'
import { NextpeakClient, ICreateOrgInviteRequest, IInviteAction } from '../services/nextpeakClient'

export const useGetOrganizationUsers = (client: NextpeakClient, organizationId: string, managerId?: string) => {
	return useQuery<unknown, Error, any[]>({
		queryKey: ['GetOrganizationUsers', organizationId],
		queryFn: () => client.getOrganizationUsers(organizationId, managerId),
		enabled: !!organizationId,
	})
}

export const useCreateOrgInvite = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: (inviteData: ICreateOrgInviteRequest) => client.createOrganizationInvite(inviteData),
		onSuccess: (res) => {
			console.log(res)
		},
		onError: (error) => {
			// Optionally handle errors here, like showing a notification
			console.error('Create of org Invite failed:', error)
		},
	})
}

export const useGetOrganizationRoles = (client: NextpeakClient) => {
	return useQuery<unknown, Error, any[]>({ queryKey: ['GetOrganizationRoles'], queryFn: () => client.getOrganizationRoles() })
}

export const useGetOrganizationInvite = (client: NextpeakClient, inviteId: string) => {
	return useQuery<unknown, Error, any>({
		queryKey: ['GetOrganizationInvite', inviteId],
		queryFn: () => client.getOrganizationInvite(inviteId),
	})
}

export const useGetUserOrganizationRoles = (client: NextpeakClient, userId?: string) => {
	return useQuery<unknown, Error, any[]>({
		queryKey: ['GetUserOrganizationRoles', userId],
		queryFn: () => client.getUserOrganizationRoles(userId!),
		enabled: !!userId,
	})
}

export const useUpdateOrgInvite = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ inviteId, inviteData }: { inviteId: string; inviteData: IInviteAction }) =>
			client.updateOrganizationInvite(inviteId, inviteData),
		onSuccess: (res) => {
			console.log(res)
		},
		onError: (error) => {
			// Optionally handle errors here, like showing a notification
			console.error('Update of org Invite failed:', error)
		},
	})
}

export const useGetUser = (client: NextpeakClient, userId: string) => {
	return useQuery<unknown, Error, any>({ queryKey: ['GetUser', userId], queryFn: () => client.getUser(userId) })
}
