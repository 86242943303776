import { GraphQLClient } from 'graphql-request'
import { RequestInit } from 'graphql-request/dist/types.dom'
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }

function fetcher<TData, TVariables extends { [key: string]: any }>(
	client: GraphQLClient,
	query: string,
	variables?: TVariables,
	requestHeaders?: RequestInit['headers']
) {
	return async (): Promise<TData> =>
		client.request({
			document: query,
			variables,
			requestHeaders,
		})
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
}

export type Answer = {
	__typename?: 'Answer'
	answerValue: Scalars['String']['output']
	assessmentResponse: AssessmentResponse
	id: Scalars['ID']['output']
	question: Question
	text: Scalars['String']['output']
}

export type AnswerDifference = {
	__typename?: 'AnswerDifference'
	otherUserAnswer?: Maybe<Scalars['Int']['output']>
	questionId?: Maybe<Scalars['String']['output']>
	thisUserAnswer?: Maybe<Scalars['Int']['output']>
}

export type AnswerInput = {
	answerValue: Scalars['Int']['input']
	comment?: InputMaybe<Scalars['String']['input']>
	questionId: Scalars['ID']['input']
}

export type Assessment = {
	__typename?: 'Assessment'
	assessmentResponses: Array<AssessmentResponse>
	assessmentType: AssessmentType
	id: Scalars['ID']['output']
	manager: User
	managerResponse?: Maybe<AssessmentResponse>
	user: User
	userResponse?: Maybe<AssessmentResponse>
}

export type AssessmentResponse = {
	__typename?: 'AssessmentResponse'
	answers: Array<Answer>
	assessment: Assessment
	averageByCategory: Array<Category>
	averageScore?: Maybe<Scalars['Float']['output']>
	completedAt?: Maybe<Scalars['String']['output']>
	createdAt: Scalars['String']['output']
	getAnswerDifferences?: Maybe<Array<Maybe<AnswerDifference>>>
	id: Scalars['ID']['output']
	user: User
}

export type AssessmentResponseGetAnswerDifferencesArgs = {
	findLargest: Scalars['Boolean']['input']
}

export type AssessmentType = {
	__typename?: 'AssessmentType'
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
	position: Position
	questions: Array<Question>
}

export type Category = {
	__typename?: 'Category'
	answers?: Maybe<Array<Maybe<Answer>>>
	average: Scalars['Float']['output']
	category?: Maybe<Scalars['String']['output']>
	subcategories: Array<Category>
}

export type CreateUserInput = {
	email: Scalars['String']['input']
	firstName: Scalars['String']['input']
	isManager: Scalars['Boolean']['input']
	kindeId: Scalars['String']['input']
	lastName: Scalars['String']['input']
}

export type Invite = {
	__typename?: 'Invite'
	email: Scalars['String']['output']
	id: Scalars['ID']['output']
	inviteSender: User
	inviteStatus: Scalars['String']['output']
	level: Scalars['String']['output']
	logtoInviteId: Scalars['String']['output']
	manager?: Maybe<User>
	organization: Organization
	position: Scalars['String']['output']
}

export type ManagerRelation = {
	__typename?: 'ManagerRelation'
	employee: User
	id: Scalars['ID']['output']
	manager: User
	organization: Organization
}

export type Mutation = {
	__typename?: 'Mutation'
	completeAssessmentResponse: AssessmentResponse
	createAssessment: Assessment
	createManagerRelation: ManagerRelation
	createUser: User
	deleteUser: User
	deleteUserInvite: Invite
	inviteUser: Invite
	saveAnswers: Array<Answer>
	setManagerRelation: ManagerRelation
	updateManagerRelation: ManagerRelation
	updateUser: User
	updateUserInvite: Invite
}

export type MutationCompleteAssessmentResponseArgs = {
	assessmentResponseId: Scalars['ID']['input']
}

export type MutationCreateAssessmentArgs = {
	employeeUserId: Scalars['ID']['input']
	managerUserId: Scalars['ID']['input']
	organizationId: Scalars['ID']['input']
}

export type MutationCreateManagerRelationArgs = {
	employeeId: Scalars['ID']['input']
	managerId: Scalars['ID']['input']
	organizationId: Scalars['ID']['input']
}

export type MutationCreateUserArgs = {
	input: CreateUserInput
}

export type MutationDeleteUserArgs = {
	userId: Scalars['ID']['input']
}

export type MutationDeleteUserInviteArgs = {
	inviteId: Scalars['ID']['input']
}

export type MutationInviteUserArgs = {
	email: Scalars['String']['input']
	level: Scalars['String']['input']
	logtoInviteId: Scalars['String']['input']
	managerId: Scalars['ID']['input']
	organizationId: Scalars['ID']['input']
	position: Scalars['String']['input']
}

export type MutationSaveAnswersArgs = {
	answers: Array<AnswerInput>
	assessmentId: Scalars['ID']['input']
}

export type MutationSetManagerRelationArgs = {
	employeeId: Scalars['ID']['input']
	managerId: Scalars['ID']['input']
}

export type MutationUpdateManagerRelationArgs = {
	input?: InputMaybe<UpdateManagerRelationInput>
}

export type MutationUpdateUserArgs = {
	input: UpdateUserInput
	userId: Scalars['ID']['input']
}

export type MutationUpdateUserInviteArgs = {
	input: UpdateUserInviteInput
	inviteId?: InputMaybe<Scalars['ID']['input']>
}

export type Organization = {
	__typename?: 'Organization'
	id: Scalars['ID']['output']
	kindeId: Scalars['String']['output']
	name: Scalars['String']['output']
}

export type Position = {
	__typename?: 'Position'
	description: Scalars['String']['output']
	id: Scalars['ID']['output']
	level: Scalars['String']['output']
	positionSections: Array<Maybe<PositionSection>>
	role: Scalars['String']['output']
	title: Scalars['String']['output']
}

export type PositionSection = {
	__typename?: 'PositionSection'
	description: Scalars['String']['output']
	header: Scalars['String']['output']
	id: Scalars['ID']['output']
	positionSectionItems: Array<Maybe<PositionSectionItem>>
}

export type PositionSectionItem = {
	__typename?: 'PositionSectionItem'
	id: Scalars['ID']['output']
	itemText: Scalars['String']['output']
}

export type PositionTrajectory = {
	__typename?: 'PositionTrajectory'
	fromPosition: Position
	id: Scalars['ID']['output']
	path: Scalars['String']['output']
	progressionOrder: Scalars['String']['output']
	toPosition: Position
}

export type Query = {
	__typename?: 'Query'
	assessment: Assessment
	assessmentResponses: Array<AssessmentResponse>
	assessmentResponsesWithinPosition?: Maybe<Array<Maybe<AssessmentResponse>>>
	assessments: Array<Assessment>
	assessmentsWithinUserPosition?: Maybe<Array<Maybe<Assessment>>>
	distinctRoles: Array<Scalars['String']['output']>
	findUserByLogtoId: Scalars['Boolean']['output']
	getAnswerDifferences?: Maybe<Array<Maybe<AnswerDifference>>>
	getAssessmentTypes?: Maybe<Array<Maybe<AssessmentType>>>
	getInvitesByOrganization: Array<Invite>
	getPositionsByOrganization: Array<Position>
	getUsersByOrganization: Array<User>
	levelsWithinRole: Array<Scalars['String']['output']>
	managerEmployees?: Maybe<Array<Maybe<User>>>
	me: User
	organizations: Array<Organization>
	position: Position
	positions: Array<Position>
	questions: Array<Question>
	userAssessments: Array<Assessment>
	users: Array<User>
}

export type QueryAssessmentArgs = {
	id: Scalars['ID']['input']
}

export type QueryAssessmentResponsesArgs = {
	userId: Scalars['ID']['input']
}

export type QueryAssessmentResponsesWithinPositionArgs = {
	latest: Scalars['Boolean']['input']
}

export type QueryAssessmentsArgs = {
	level?: InputMaybe<Scalars['String']['input']>
	role?: InputMaybe<Scalars['String']['input']>
}

export type QueryAssessmentsWithinUserPositionArgs = {
	isCompleted?: InputMaybe<Scalars['Boolean']['input']>
	latest: Scalars['Boolean']['input']
}

export type QueryFindUserByLogtoIdArgs = {
	userId: Scalars['ID']['input']
}

export type QueryGetAnswerDifferencesArgs = {
	findLargest: Scalars['Boolean']['input']
}

export type QueryGetInvitesByOrganizationArgs = {
	organizationId: Scalars['ID']['input']
}

export type QueryGetPositionsByOrganizationArgs = {
	organizationId: Scalars['ID']['input']
}

export type QueryGetUsersByOrganizationArgs = {
	organizationId: Scalars['ID']['input']
}

export type QueryLevelsWithinRoleArgs = {
	roleTitle?: InputMaybe<Scalars['String']['input']>
}

export type QueryManagerEmployeesArgs = {
	managerId: Scalars['ID']['input']
}

export type QueryPositionArgs = {
	roleLevel?: InputMaybe<Scalars['String']['input']>
	roleTitle?: InputMaybe<Scalars['String']['input']>
}

export type QueryUserAssessmentsArgs = {
	userId: Scalars['ID']['input']
	userIsManager: Scalars['Boolean']['input']
}

export type Question = {
	__typename?: 'Question'
	assessment?: Maybe<Assessment>
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
	responseType: Scalars['String']['output']
	subName?: Maybe<Scalars['String']['output']>
	text: Scalars['String']['output']
}

export type UpdateManagerRelationInput = {
	employeeId: Scalars['String']['input']
	managerId: Scalars['String']['input']
}

export type UpdateUserInput = {
	email?: InputMaybe<Scalars['String']['input']>
	firstName?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserInviteInput = {
	email?: InputMaybe<Scalars['String']['input']>
	inviteStatus?: InputMaybe<Scalars['String']['input']>
}

export type User = {
	__typename?: 'User'
	assessmentResponses?: Maybe<Array<Maybe<AssessmentResponse>>>
	email: Scalars['String']['output']
	firstName: Scalars['String']['output']
	id: Scalars['ID']['output']
	isAdmin: Scalars['Boolean']['output']
	isManager: Scalars['Boolean']['output']
	kindeId: Scalars['ID']['output']
	lastName: Scalars['String']['output']
	managers: Array<User>
	organizations: Array<Organization>
	subordinates: Array<User>
	userPosition?: Maybe<UserPosition>
}

export type UserManagersArgs = {
	organizationId: Scalars['ID']['input']
}

export type UserSubordinatesArgs = {
	organizationId: Scalars['ID']['input']
}

export type UserPosition = {
	__typename?: 'UserPosition'
	id: Scalars['ID']['output']
	position: Position
	positionTrajectory: PositionTrajectory
	sinceDate: Scalars['String']['output']
	untilDate?: Maybe<Scalars['String']['output']>
}

export type UserResponse = {
	__typename?: 'UserResponse'
	answers: Array<Answer>
	assessment: Assessment
	averageScore?: Maybe<Scalars['Float']['output']>
	completedAt?: Maybe<Scalars['String']['output']>
	createdAt: Scalars['String']['output']
	id: Scalars['ID']['output']
	user: User
}

export type CreateUserMutationVariables = Exact<{
	input: CreateUserInput
}>

export type CreateUserMutation = {
	__typename?: 'Mutation'
	createUser: { __typename?: 'User'; id: string; firstName: string; lastName: string; email: string; kindeId: string; isManager: boolean }
}

export type DeleteUserInviteMutationVariables = Exact<{
	inviteId: Scalars['ID']['input']
}>

export type DeleteUserInviteMutation = { __typename?: 'Mutation'; deleteUserInvite: { __typename?: 'Invite'; id: string } }

export type FindUserByLogtoIdQueryVariables = Exact<{
	userId: Scalars['ID']['input']
}>

export type FindUserByLogtoIdQuery = { __typename?: 'Query'; findUserByLogtoId: boolean }

export type GetInvitesByOrganizationQueryVariables = Exact<{
	organizationId: Scalars['ID']['input']
}>

export type GetInvitesByOrganizationQuery = {
	__typename?: 'Query'
	getInvitesByOrganization: Array<{
		__typename?: 'Invite'
		id: string
		email: string
		inviteStatus: string
		level: string
		position: string
		manager?: { __typename?: 'User'; id: string; firstName: string; lastName: string; kindeId: string } | null
	}>
}

export type GetUsersByOrganizationQueryVariables = Exact<{
	organizationId: Scalars['ID']['input']
}>

export type GetUsersByOrganizationQuery = {
	__typename?: 'Query'
	getUsersByOrganization: Array<{
		__typename?: 'User'
		id: string
		email: string
		firstName: string
		lastName: string
		kindeId: string
		userPosition?: {
			__typename?: 'UserPosition'
			sinceDate: string
			untilDate?: string | null
			id: string
			position: { __typename?: 'Position'; title: string; level: string; id: string }
			positionTrajectory: {
				__typename?: 'PositionTrajectory'
				progressionOrder: string
				path: string
				id: string
				fromPosition: { __typename?: 'Position'; title: string; level: string }
				toPosition: { __typename?: 'Position'; title: string; level: string; id: string }
			}
		} | null
		managers: Array<{ __typename?: 'User'; id: string; firstName: string; lastName: string; kindeId: string }>
		subordinates: Array<{ __typename?: 'User'; id: string; firstName: string; lastName: string; kindeId: string }>
	}>
}

export type InviteUserMutationVariables = Exact<{
	email: Scalars['String']['input']
	level: Scalars['String']['input']
	managerId: Scalars['ID']['input']
	organizationId: Scalars['ID']['input']
	position: Scalars['String']['input']
	logtoInviteId: Scalars['String']['input']
}>

export type InviteUserMutation = {
	__typename?: 'Mutation'
	inviteUser: {
		__typename?: 'Invite'
		id: string
		email: string
		level: string
		position: string
		inviteSender: { __typename?: 'User'; id: string; firstName: string }
		manager?: { __typename?: 'User'; id: string; firstName: string } | null
		organization: { __typename?: 'Organization'; id: string; name: string }
	}
}

export type UpdateManagerRelationMutationVariables = Exact<{
	input: UpdateManagerRelationInput
}>

export type UpdateManagerRelationMutation = {
	__typename?: 'Mutation'
	updateManagerRelation: { __typename?: 'ManagerRelation'; id: string }
}

export type AssessmentQueryVariables = Exact<{
	assessmentId: Scalars['ID']['input']
}>

export type AssessmentQuery = {
	__typename?: 'Query'
	assessment: {
		__typename?: 'Assessment'
		userResponse?: {
			__typename?: 'AssessmentResponse'
			id: string
			completedAt?: string | null
			user: { __typename?: 'User'; id: string }
			answers: Array<{ __typename?: 'Answer'; id: string; answerValue: string }>
		} | null
		managerResponse?: {
			__typename?: 'AssessmentResponse'
			id: string
			completedAt?: string | null
			user: { __typename?: 'User'; id: string }
			answers: Array<{ __typename?: 'Answer'; id: string; answerValue: string }>
		} | null
		assessmentType: {
			__typename?: 'AssessmentType'
			name: string
			questions: Array<{ __typename?: 'Question'; id: string; name: string; subName?: string | null; text: string }>
			position: { __typename?: 'Position'; id: string; title: string; level: string; role: string; description: string }
		}
	}
}

export type AssessmentResponsesQueryVariables = Exact<{
	userId: Scalars['ID']['input']
}>

export type AssessmentResponsesQuery = {
	__typename?: 'Query'
	assessmentResponses: Array<{
		__typename?: 'AssessmentResponse'
		id: string
		averageScore?: number | null
		averageByCategory: Array<{
			__typename?: 'Category'
			category?: string | null
			average: number
			subcategories: Array<{
				__typename?: 'Category'
				average: number
				category?: string | null
				answers?: Array<{
					__typename?: 'Answer'
					id: string
					answerValue: string
					question: { __typename?: 'Question'; id: string; name: string; subName?: string | null; text: string }
				} | null> | null
			}>
		}>
	}>
}

export type AssessmentWithResponsesQueryVariables = Exact<{
	assessmentId: Scalars['ID']['input']
}>

export type AssessmentWithResponsesQuery = {
	__typename?: 'Query'
	assessment: {
		__typename?: 'Assessment'
		id: string
		userResponse?: { __typename?: 'AssessmentResponse'; id: string; completedAt?: string | null } | null
		managerResponse?: { __typename?: 'AssessmentResponse'; id: string; completedAt?: string | null } | null
		assessmentResponses: Array<{
			__typename?: 'AssessmentResponse'
			id: string
			averageScore?: number | null
			averageByCategory: Array<{
				__typename?: 'Category'
				category?: string | null
				average: number
				subcategories: Array<{
					__typename?: 'Category'
					category?: string | null
					average: number
					answers?: Array<{
						__typename?: 'Answer'
						id: string
						answerValue: string
						question: { __typename?: 'Question'; id: string; name: string; subName?: string | null; text: string }
					} | null> | null
				}>
			}>
		}>
	}
}

export type CompleteAssessmentResponseMutationVariables = Exact<{
	assessmentResponseId: Scalars['ID']['input']
}>

export type CompleteAssessmentResponseMutation = {
	__typename?: 'Mutation'
	completeAssessmentResponse: { __typename?: 'AssessmentResponse'; id: string }
}

export type CreateAssessmentMutationVariables = Exact<{
	employeeUserId: Scalars['ID']['input']
	organizationId: Scalars['ID']['input']
	managerUserId: Scalars['ID']['input']
}>

export type CreateAssessmentMutation = { __typename?: 'Mutation'; createAssessment: { __typename?: 'Assessment'; id: string } }

export type SaveAnswersMutationVariables = Exact<{
	answers: Array<AnswerInput> | AnswerInput
	assessmentId: Scalars['ID']['input']
}>

export type SaveAnswersMutation = { __typename?: 'Mutation'; saveAnswers: Array<{ __typename?: 'Answer'; id: string }> }

export type UserAssessmentsQueryVariables = Exact<{
	userId: Scalars['ID']['input']
	userIsManager: Scalars['Boolean']['input']
}>

export type UserAssessmentsQuery = {
	__typename?: 'Query'
	userAssessments: Array<{
		__typename?: 'Assessment'
		id: string
		user: { __typename?: 'User'; firstName: string; lastName: string; id: string }
		assessmentType: {
			__typename?: 'AssessmentType'
			name: string
			position: { __typename?: 'Position'; id: string; title: string; level: string }
			questions: Array<{ __typename?: 'Question'; id: string; name: string; text: string; subName?: string | null; responseType: string }>
		}
		userResponse?: {
			__typename?: 'AssessmentResponse'
			id: string
			completedAt?: string | null
			user: { __typename?: 'User'; id: string }
		} | null
		managerResponse?: {
			__typename?: 'AssessmentResponse'
			id: string
			completedAt?: string | null
			user: { __typename?: 'User'; id: string }
		} | null
	}>
}

export type AssessmentsWithinUserPositionQueryVariables = Exact<{
	latest: Scalars['Boolean']['input']
	isCompleted?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AssessmentsWithinUserPositionQuery = {
	__typename?: 'Query'
	assessmentsWithinUserPosition?: Array<{
		__typename?: 'Assessment'
		id: string
		userResponse?: { __typename?: 'AssessmentResponse'; id: string; averageScore?: number | null; completedAt?: string | null } | null
		managerResponse?: { __typename?: 'AssessmentResponse'; id: string; averageScore?: number | null; completedAt?: string | null } | null
	} | null> | null
}

export type GetAssessmentTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetAssessmentTypesQuery = {
	__typename?: 'Query'
	getAssessmentTypes?: Array<{
		__typename?: 'AssessmentType'
		id: string
		name: string
		position: { __typename?: 'Position'; id: string; title: string; level: string }
	} | null> | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
	__typename?: 'Query'
	me: {
		__typename?: 'User'
		id: string
		isManager: boolean
		isAdmin: boolean
		email: string
		firstName: string
		lastName: string
		userPosition?: {
			__typename?: 'UserPosition'
			sinceDate: string
			untilDate?: string | null
			id: string
			position: { __typename?: 'Position'; title: string; level: string; id: string; role: string }
			positionTrajectory: {
				__typename?: 'PositionTrajectory'
				progressionOrder: string
				path: string
				id: string
				fromPosition: { __typename?: 'Position'; id: string; title: string; level: string; role: string }
				toPosition: { __typename?: 'Position'; title: string; level: string; id: string; role: string }
			}
		} | null
		organizations: Array<{ __typename?: 'Organization'; id: string; name: string; kindeId: string }>
	}
}

export type MySubordinatesQueryVariables = Exact<{
	organizationId: Scalars['ID']['input']
}>

export type MySubordinatesQuery = {
	__typename?: 'Query'
	me: { __typename?: 'User'; subordinates: Array<{ __typename?: 'User'; id: string; email: string; firstName: string; lastName: string }> }
}

export type MeIdQueryVariables = Exact<{ [key: string]: never }>

export type MeIdQuery = {
	__typename?: 'Query'
	me: {
		__typename?: 'User'
		id: string
		isManager: boolean
		organizations: Array<{ __typename?: 'Organization'; id: string; name: string; kindeId: string }>
	}
}

export type PositionQueryVariables = Exact<{
	roleTitle: Scalars['String']['input']
	roleLevel: Scalars['String']['input']
}>

export type PositionQuery = {
	__typename?: 'Query'
	position: {
		__typename?: 'Position'
		id: string
		title: string
		level: string
		role: string
		description: string
		positionSections: Array<{
			__typename?: 'PositionSection'
			id: string
			header: string
			positionSectionItems: Array<{ __typename?: 'PositionSectionItem'; id: string; itemText: string } | null>
		} | null>
	}
}

export type GetPositionsQueryVariables = Exact<{ [key: string]: never }>

export type GetPositionsQuery = {
	__typename?: 'Query'
	positions: Array<{ __typename?: 'Position'; id: string; title: string; level: string; role: string; description: string }>
}

export const CreateUserDocument = `
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    firstName
    lastName
    email
    kindeId
    isManager
  }
}
    `

export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>,
	headers?: RequestInit['headers']
) => {
	return useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>({
		mutationKey: ['CreateUser'],
		mutationFn: (variables?: CreateUserMutationVariables) =>
			fetcher<CreateUserMutation, CreateUserMutationVariables>(client, CreateUserDocument, variables, headers)(),
		...options,
	})
}

export const DeleteUserInviteDocument = `
    mutation DeleteUserInvite($inviteId: ID!) {
  deleteUserInvite(inviteId: $inviteId) {
    id
  }
}
    `

export const useDeleteUserInviteMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<DeleteUserInviteMutation, TError, DeleteUserInviteMutationVariables, TContext>,
	headers?: RequestInit['headers']
) => {
	return useMutation<DeleteUserInviteMutation, TError, DeleteUserInviteMutationVariables, TContext>({
		mutationKey: ['DeleteUserInvite'],
		mutationFn: (variables?: DeleteUserInviteMutationVariables) =>
			fetcher<DeleteUserInviteMutation, DeleteUserInviteMutationVariables>(client, DeleteUserInviteDocument, variables, headers)(),
		...options,
	})
}

export const FindUserByLogtoIdDocument = `
    query FindUserByLogtoId($userId: ID!) {
  findUserByLogtoId(userId: $userId)
}
    `

export const useFindUserByLogtoIdQuery = <TData = FindUserByLogtoIdQuery, TError = unknown>(
	client: GraphQLClient,
	variables: FindUserByLogtoIdQueryVariables,
	options?: Omit<UseQueryOptions<FindUserByLogtoIdQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<FindUserByLogtoIdQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<FindUserByLogtoIdQuery, TError, TData>({
		queryKey: ['FindUserByLogtoId', variables],
		queryFn: fetcher<FindUserByLogtoIdQuery, FindUserByLogtoIdQueryVariables>(client, FindUserByLogtoIdDocument, variables, headers),
		...options,
	})
}

export const GetInvitesByOrganizationDocument = `
    query GetInvitesByOrganization($organizationId: ID!) {
  getInvitesByOrganization(organizationId: $organizationId) {
    id
    email
    inviteStatus
    level
    position
    manager {
      id
      firstName
      lastName
      kindeId
    }
  }
}
    `

export const useGetInvitesByOrganizationQuery = <TData = GetInvitesByOrganizationQuery, TError = unknown>(
	client: GraphQLClient,
	variables: GetInvitesByOrganizationQueryVariables,
	options?: Omit<UseQueryOptions<GetInvitesByOrganizationQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<GetInvitesByOrganizationQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<GetInvitesByOrganizationQuery, TError, TData>({
		queryKey: ['GetInvitesByOrganization', variables],
		queryFn: fetcher<GetInvitesByOrganizationQuery, GetInvitesByOrganizationQueryVariables>(
			client,
			GetInvitesByOrganizationDocument,
			variables,
			headers
		),
		...options,
	})
}

export const GetUsersByOrganizationDocument = `
    query GetUsersByOrganization($organizationId: ID!) {
  getUsersByOrganization(organizationId: $organizationId) {
    id
    email
    firstName
    lastName
    kindeId
    userPosition {
      position {
        title
        level
        id
      }
      sinceDate
      untilDate
      positionTrajectory {
        progressionOrder
        path
        fromPosition {
          title
          level
        }
        toPosition {
          title
          level
          id
        }
        id
      }
      id
    }
    managers(organizationId: $organizationId) {
      id
      firstName
      lastName
      kindeId
    }
    subordinates(organizationId: $organizationId) {
      id
      firstName
      lastName
      kindeId
    }
  }
}
    `

export const useGetUsersByOrganizationQuery = <TData = GetUsersByOrganizationQuery, TError = unknown>(
	client: GraphQLClient,
	variables: GetUsersByOrganizationQueryVariables,
	options?: Omit<UseQueryOptions<GetUsersByOrganizationQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<GetUsersByOrganizationQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<GetUsersByOrganizationQuery, TError, TData>({
		queryKey: ['GetUsersByOrganization', variables],
		queryFn: fetcher<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(
			client,
			GetUsersByOrganizationDocument,
			variables,
			headers
		),
		...options,
	})
}

export const InviteUserDocument = `
    mutation InviteUser($email: String!, $level: String!, $managerId: ID!, $organizationId: ID!, $position: String!, $logtoInviteId: String!) {
  inviteUser(
    email: $email
    level: $level
    managerId: $managerId
    organizationId: $organizationId
    position: $position
    logtoInviteId: $logtoInviteId
  ) {
    id
    email
    inviteSender {
      id
      firstName
    }
    level
    manager {
      id
      firstName
    }
    organization {
      id
      name
    }
    position
  }
}
    `

export const useInviteUserMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<InviteUserMutation, TError, InviteUserMutationVariables, TContext>,
	headers?: RequestInit['headers']
) => {
	return useMutation<InviteUserMutation, TError, InviteUserMutationVariables, TContext>({
		mutationKey: ['InviteUser'],
		mutationFn: (variables?: InviteUserMutationVariables) =>
			fetcher<InviteUserMutation, InviteUserMutationVariables>(client, InviteUserDocument, variables, headers)(),
		...options,
	})
}

export const UpdateManagerRelationDocument = `
    mutation UpdateManagerRelation($input: UpdateManagerRelationInput!) {
  updateManagerRelation(input: $input) {
    id
  }
}
    `

export const useUpdateManagerRelationMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<UpdateManagerRelationMutation, TError, UpdateManagerRelationMutationVariables, TContext>,
	headers?: RequestInit['headers']
) => {
	return useMutation<UpdateManagerRelationMutation, TError, UpdateManagerRelationMutationVariables, TContext>({
		mutationKey: ['UpdateManagerRelation'],
		mutationFn: (variables?: UpdateManagerRelationMutationVariables) =>
			fetcher<UpdateManagerRelationMutation, UpdateManagerRelationMutationVariables>(
				client,
				UpdateManagerRelationDocument,
				variables,
				headers
			)(),
		...options,
	})
}

export const AssessmentDocument = `
    query Assessment($assessmentId: ID!) {
  assessment(id: $assessmentId) {
    userResponse {
      id
      user {
        id
      }
      completedAt
      answers {
        id
        answerValue
      }
    }
    managerResponse {
      id
      user {
        id
      }
      completedAt
      answers {
        id
        answerValue
      }
    }
    assessmentType {
      name
      questions {
        id
        name
        subName
        text
      }
      position {
        id
        title
        level
        role
        description
      }
    }
  }
}
    `

export const useAssessmentQuery = <TData = AssessmentQuery, TError = unknown>(
	client: GraphQLClient,
	variables: AssessmentQueryVariables,
	options?: Omit<UseQueryOptions<AssessmentQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<AssessmentQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<AssessmentQuery, TError, TData>({
		queryKey: ['Assessment', variables],
		queryFn: fetcher<AssessmentQuery, AssessmentQueryVariables>(client, AssessmentDocument, variables, headers),
		...options,
	})
}

export const AssessmentResponsesDocument = `
    query AssessmentResponses($userId: ID!) {
  assessmentResponses(userId: $userId) {
    id
    averageScore
    averageByCategory {
      category
      average
      subcategories {
        average
        category
        answers {
          id
          answerValue
          question {
            id
            name
            subName
            text
          }
        }
      }
    }
  }
}
    `

export const useAssessmentResponsesQuery = <TData = AssessmentResponsesQuery, TError = unknown>(
	client: GraphQLClient,
	variables: AssessmentResponsesQueryVariables,
	options?: Omit<UseQueryOptions<AssessmentResponsesQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<AssessmentResponsesQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<AssessmentResponsesQuery, TError, TData>({
		queryKey: ['AssessmentResponses', variables],
		queryFn: fetcher<AssessmentResponsesQuery, AssessmentResponsesQueryVariables>(client, AssessmentResponsesDocument, variables, headers),
		...options,
	})
}

export const AssessmentWithResponsesDocument = `
    query AssessmentWithResponses($assessmentId: ID!) {
  assessment(id: $assessmentId) {
    id
    userResponse {
      id
      completedAt
    }
    managerResponse {
      id
      completedAt
    }
    assessmentResponses {
      id
      averageScore
      averageByCategory {
        category
        average
        subcategories {
          category
          average
          answers {
            id
            answerValue
            question {
              id
              name
              subName
              text
            }
          }
        }
      }
    }
  }
}
    `

export const useAssessmentWithResponsesQuery = <TData = AssessmentWithResponsesQuery, TError = unknown>(
	client: GraphQLClient,
	variables: AssessmentWithResponsesQueryVariables,
	options?: Omit<UseQueryOptions<AssessmentWithResponsesQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<AssessmentWithResponsesQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<AssessmentWithResponsesQuery, TError, TData>({
		queryKey: ['AssessmentWithResponses', variables],
		queryFn: fetcher<AssessmentWithResponsesQuery, AssessmentWithResponsesQueryVariables>(
			client,
			AssessmentWithResponsesDocument,
			variables,
			headers
		),
		...options,
	})
}

export const CompleteAssessmentResponseDocument = `
    mutation CompleteAssessmentResponse($assessmentResponseId: ID!) {
  completeAssessmentResponse(assessmentResponseId: $assessmentResponseId) {
    id
  }
}
    `

export const useCompleteAssessmentResponseMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<CompleteAssessmentResponseMutation, TError, CompleteAssessmentResponseMutationVariables, TContext>,
	headers?: RequestInit['headers']
) => {
	return useMutation<CompleteAssessmentResponseMutation, TError, CompleteAssessmentResponseMutationVariables, TContext>({
		mutationKey: ['CompleteAssessmentResponse'],
		mutationFn: (variables?: CompleteAssessmentResponseMutationVariables) =>
			fetcher<CompleteAssessmentResponseMutation, CompleteAssessmentResponseMutationVariables>(
				client,
				CompleteAssessmentResponseDocument,
				variables,
				headers
			)(),
		...options,
	})
}

export const CreateAssessmentDocument = `
    mutation CreateAssessment($employeeUserId: ID!, $organizationId: ID!, $managerUserId: ID!) {
  createAssessment(
    employeeUserId: $employeeUserId
    organizationId: $organizationId
    managerUserId: $managerUserId
  ) {
    id
  }
}
    `

export const useCreateAssessmentMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<CreateAssessmentMutation, TError, CreateAssessmentMutationVariables, TContext>,
	headers?: RequestInit['headers']
) => {
	return useMutation<CreateAssessmentMutation, TError, CreateAssessmentMutationVariables, TContext>({
		mutationKey: ['CreateAssessment'],
		mutationFn: (variables?: CreateAssessmentMutationVariables) =>
			fetcher<CreateAssessmentMutation, CreateAssessmentMutationVariables>(client, CreateAssessmentDocument, variables, headers)(),
		...options,
	})
}

export const SaveAnswersDocument = `
    mutation SaveAnswers($answers: [AnswerInput!]!, $assessmentId: ID!) {
  saveAnswers(answers: $answers, assessmentId: $assessmentId) {
    id
  }
}
    `

export const useSaveAnswersMutation = <TError = unknown, TContext = unknown>(
	client: GraphQLClient,
	options?: UseMutationOptions<SaveAnswersMutation, TError, SaveAnswersMutationVariables, TContext>,
	headers?: RequestInit['headers']
) => {
	return useMutation<SaveAnswersMutation, TError, SaveAnswersMutationVariables, TContext>({
		mutationKey: ['SaveAnswers'],
		mutationFn: (variables?: SaveAnswersMutationVariables) =>
			fetcher<SaveAnswersMutation, SaveAnswersMutationVariables>(client, SaveAnswersDocument, variables, headers)(),
		...options,
	})
}

export const UserAssessmentsDocument = `
    query UserAssessments($userId: ID!, $userIsManager: Boolean!) {
  userAssessments(userId: $userId, userIsManager: $userIsManager) {
    id
    user {
      firstName
      lastName
      id
    }
    assessmentType {
      name
      position {
        id
        title
        level
      }
      questions {
        id
        name
        text
        subName
        responseType
      }
    }
    userResponse {
      id
      user {
        id
      }
      completedAt
    }
    managerResponse {
      id
      user {
        id
      }
      completedAt
    }
  }
}
    `

export const useUserAssessmentsQuery = <TData = UserAssessmentsQuery, TError = unknown>(
	client: GraphQLClient,
	variables: UserAssessmentsQueryVariables,
	options?: Omit<UseQueryOptions<UserAssessmentsQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<UserAssessmentsQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<UserAssessmentsQuery, TError, TData>({
		queryKey: ['UserAssessments', variables],
		queryFn: fetcher<UserAssessmentsQuery, UserAssessmentsQueryVariables>(client, UserAssessmentsDocument, variables, headers),
		...options,
	})
}

export const AssessmentsWithinUserPositionDocument = `
    query AssessmentsWithinUserPosition($latest: Boolean!, $isCompleted: Boolean) {
  assessmentsWithinUserPosition(latest: $latest, isCompleted: $isCompleted) {
    id
    userResponse {
      id
      averageScore
      completedAt
    }
    managerResponse {
      id
      averageScore
      completedAt
    }
  }
}
    `

export const useAssessmentsWithinUserPositionQuery = <TData = AssessmentsWithinUserPositionQuery, TError = unknown>(
	client: GraphQLClient,
	variables: AssessmentsWithinUserPositionQueryVariables,
	options?: Omit<UseQueryOptions<AssessmentsWithinUserPositionQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<AssessmentsWithinUserPositionQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<AssessmentsWithinUserPositionQuery, TError, TData>({
		queryKey: ['AssessmentsWithinUserPosition', variables],
		queryFn: fetcher<AssessmentsWithinUserPositionQuery, AssessmentsWithinUserPositionQueryVariables>(
			client,
			AssessmentsWithinUserPositionDocument,
			variables,
			headers
		),
		...options,
	})
}

export const GetAssessmentTypesDocument = `
    query GetAssessmentTypes {
  getAssessmentTypes {
    id
    name
    position {
      id
      title
      level
    }
  }
}
    `

export const useGetAssessmentTypesQuery = <TData = GetAssessmentTypesQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: GetAssessmentTypesQueryVariables,
	options?: Omit<UseQueryOptions<GetAssessmentTypesQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<GetAssessmentTypesQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<GetAssessmentTypesQuery, TError, TData>({
		queryKey: variables === undefined ? ['GetAssessmentTypes'] : ['GetAssessmentTypes', variables],
		queryFn: fetcher<GetAssessmentTypesQuery, GetAssessmentTypesQueryVariables>(client, GetAssessmentTypesDocument, variables, headers),
		...options,
	})
}

export const MeDocument = `
    query Me {
  me {
    id
    isManager
    isAdmin
    email
    firstName
    lastName
    userPosition {
      position {
        title
        level
        id
        role
      }
      sinceDate
      untilDate
      positionTrajectory {
        progressionOrder
        path
        fromPosition {
          id
          title
          level
          role
        }
        toPosition {
          title
          level
          id
          role
        }
        id
      }
      id
    }
    organizations {
      id
      name
      kindeId
    }
  }
}
    `

export const useMeQuery = <TData = MeQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: MeQueryVariables,
	options?: Omit<UseQueryOptions<MeQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<MeQuery, TError, TData>['queryKey'] },
	headers?: RequestInit['headers']
) => {
	return useQuery<MeQuery, TError, TData>({
		queryKey: variables === undefined ? ['Me'] : ['Me', variables],
		queryFn: fetcher<MeQuery, MeQueryVariables>(client, MeDocument, variables, headers),
		...options,
	})
}

export const MySubordinatesDocument = `
    query MySubordinates($organizationId: ID!) {
  me {
    subordinates(organizationId: $organizationId) {
      id
      email
      firstName
      lastName
    }
  }
}
    `

export const useMySubordinatesQuery = <TData = MySubordinatesQuery, TError = unknown>(
	client: GraphQLClient,
	variables: MySubordinatesQueryVariables,
	options?: Omit<UseQueryOptions<MySubordinatesQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<MySubordinatesQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<MySubordinatesQuery, TError, TData>({
		queryKey: ['MySubordinates', variables],
		queryFn: fetcher<MySubordinatesQuery, MySubordinatesQueryVariables>(client, MySubordinatesDocument, variables, headers),
		...options,
	})
}

export const MeIdDocument = `
    query MeId {
  me {
    id
    isManager
    organizations {
      id
      name
      kindeId
    }
  }
}
    `

export const useMeIdQuery = <TData = MeIdQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: MeIdQueryVariables,
	options?: Omit<UseQueryOptions<MeIdQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<MeIdQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<MeIdQuery, TError, TData>({
		queryKey: variables === undefined ? ['MeId'] : ['MeId', variables],
		queryFn: fetcher<MeIdQuery, MeIdQueryVariables>(client, MeIdDocument, variables, headers),
		...options,
	})
}

export const PositionDocument = `
    query Position($roleTitle: String!, $roleLevel: String!) {
  position(roleTitle: $roleTitle, roleLevel: $roleLevel) {
    id
    title
    level
    role
    description
    positionSections {
      id
      header
      positionSectionItems {
        id
        itemText
      }
    }
  }
}
    `

export const usePositionQuery = <TData = PositionQuery, TError = unknown>(
	client: GraphQLClient,
	variables: PositionQueryVariables,
	options?: Omit<UseQueryOptions<PositionQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<PositionQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<PositionQuery, TError, TData>({
		queryKey: ['Position', variables],
		queryFn: fetcher<PositionQuery, PositionQueryVariables>(client, PositionDocument, variables, headers),
		...options,
	})
}

export const GetPositionsDocument = `
    query GetPositions {
  positions {
    id
    title
    level
    role
    description
  }
}
    `

export const useGetPositionsQuery = <TData = GetPositionsQuery, TError = unknown>(
	client: GraphQLClient,
	variables?: GetPositionsQueryVariables,
	options?: Omit<UseQueryOptions<GetPositionsQuery, TError, TData>, 'queryKey'> & {
		queryKey?: UseQueryOptions<GetPositionsQuery, TError, TData>['queryKey']
	},
	headers?: RequestInit['headers']
) => {
	return useQuery<GetPositionsQuery, TError, TData>({
		queryKey: variables === undefined ? ['GetPositions'] : ['GetPositions', variables],
		queryFn: fetcher<GetPositionsQuery, GetPositionsQueryVariables>(client, GetPositionsDocument, variables, headers),
		...options,
	})
}
