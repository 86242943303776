import {
	getOrganizationUsers,
	createOrganizationInvite,
	getOrganizationRoles,
	updateOrganizationInvite,
	IInviteAction,
	getOrganizationInvite,
	ICreateOrgInviteRequest,
	getUserOrganizationRoles,
	getUser,
} from './endpoints'
import { BearerAuthenticator, HttpClient, HttpClientFactory, NoAuthAuthenticator } from '../httpClient'

export interface INextpeakClientOpts {
	baseUrl: string
	apiToken?: string
}

export class NextpeakClient {
	readonly client: HttpClient

	constructor(
		private readonly opts: INextpeakClientOpts,
		httpClientFactory: HttpClientFactory
	) {
		this.client = httpClientFactory.createClient(this.opts.baseUrl, {
			clientName: this.constructor.name,
			defaultAuthenticator: this.opts.apiToken
				? new BearerAuthenticator(async () => {
						return {
							token: this.opts.apiToken!,
							expiresInSeconds: 3600,
						}
					})
				: new NoAuthAuthenticator(),
		})
	}

	async getOrganizationUsers(organizationId: string, managerId?: string) {
		return getOrganizationUsers(this.client, organizationId, managerId)
	}

	async createOrganizationInvite(inviteData: ICreateOrgInviteRequest) {
		return createOrganizationInvite(this.client, inviteData)
	}

	async getOrganizationRoles() {
		return getOrganizationRoles(this.client)
	}

	async getOrganizationInvite(inviteId: string) {
		return getOrganizationInvite(this.client, inviteId)
	}

	async updateOrganizationInvite(inviteId: string, inviteAction: IInviteAction) {
		return updateOrganizationInvite(this.client, inviteId, inviteAction)
	}

	async getUserOrganizationRoles(userId: string) {
		return getUserOrganizationRoles(this.client, userId)
	}

	async getUser(userId: string) {
		return getUser(this.client, userId)
	}
}
