import PageTitle from './components/PageTitle'
import App from './App.tsx'
import { OrgAuthContext } from './OrgAuthProvider.ts'

interface OrganizationSelectorProps {
	organizations: any[]
	handleOrganizationChange: (org: any) => void
}

function OrganizationSelector({ organizations, handleOrganizationChange }: OrganizationSelectorProps) {
	return (
		<div>
			<PageTitle title="Select Organization" />
			<div>
				{organizations.map((org: any) => (
					<div key={org.id}>
						<button onClick={() => handleOrganizationChange(org)}>{org.name}</button>
					</div>
				))}
			</div>
		</div>
	)
}

interface OrganizationAuthProps {
	handleOrganizationChange: (org: any) => Promise<void>
	activeOrganizationAuth?: any
	organizationToken?: string
	userOrganizations: any[]
}

function OrganizationAuth({
	handleOrganizationChange,
	organizationToken,
	activeOrganizationAuth,
	userOrganizations,
}: OrganizationAuthProps) {
	return (
		<OrgAuthContext.Provider
			value={{ token: organizationToken, authOrgUser: activeOrganizationAuth, handleOrganizationChange, userOrganizations }}
		>
			{!organizationToken && <OrganizationSelector organizations={userOrganizations} handleOrganizationChange={handleOrganizationChange} />}
			{organizationToken && <App />}
		</OrgAuthContext.Provider>
	)
}

export default OrganizationAuth
