import { GraphQLClient } from 'graphql-request'
import { useMemo } from 'react'
import { NEXTPEAK_API_URL } from './config'
import { useAuth } from './AuthProvider.ts'
import { useOrgAuth } from './OrgAuthProvider.ts'

export const useGraphQLClient = () => {
	const { token: clientToken } = useAuth()
	const { token: orgToken } = useOrgAuth()
	return useMemo(() => {
		const options = {
			headers: new Headers(),
		}

		if (orgToken) {
			options.headers.append('Authorization', `Bearer ${orgToken}`)
		} else if (clientToken) {
			options.headers.append('Authorization', `Bearer ${clientToken}`)
		}

		return new GraphQLClient(NEXTPEAK_API_URL, options)
	}, [orgToken, clientToken])
}
