import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import PageTitle from './components/PageTitle'
import Account from './pages/Account'
import { useAuth } from './AuthProvider.ts'
import Home from './pages/Home'
import Assessments from './pages/Assessments'
import Billing from './pages/Billing'
import OrganizationSettings from './pages/Organization/Settings.tsx'
import OrganizationOverview from './pages/Organization/Overview.tsx'
import Employees from './pages/Employees'
import AssessmentDetail from './pages/Assessments/Detail.tsx'
import AssessmentSurvey from './pages/Assessments/Survey.tsx'
import { useGraphQLClient } from './useGraphQLClient.ts'
import { MeQuery, useMeQuery } from './generated/graphql.ts'
import { MeContext } from './MeProvider.ts'
import EmployeeDetail from './pages/Employees/Detail.tsx'
import DevKit from './pages/DevKit'
import FullPageLoader from './common/FullPageLoader'
import { useRestClient } from './useRestClient.ts'
import { useGetUserOrganizationRoles } from './http/hooks.ts'
import EmployeesList from './pages/Organization/EmployeesList.tsx'

function App() {
	const [searchParams] = useSearchParams()
	const [user, setUser] = useState<MeQuery['me'] | undefined>()
	const [roleArray, setRoleArray] = useState<string[]>([])
	const { authUser } = useAuth()
	const restClient = useRestClient()
	const navigate = useNavigate() // Hook for programmatic navigation
	const graphQlClient = useGraphQLClient()

	const { data: me, isLoading } = useMeQuery(graphQlClient)
	const { data: roleData, isLoading: roleLoader, error: roleError } = useGetUserOrganizationRoles(restClient, me?.me.id) // TODO: This is wrong! We are asking about the root user's roles, not the organization's roles

	useEffect(() => {
		const redirect = searchParams.get('redirect')
		localStorage.removeItem('postLoginRedirect')
		if (redirect) {
			navigate(redirect)
		}
	}, [])

	useEffect(() => {
		if (roleData) {
			const orgRolesArray = roleData.map((item: any) => item.name)
			setRoleArray(orgRolesArray)
		}
		if (roleError) {
			console.error(roleError)
		}
	}, [roleData, roleLoader, roleError])

	useEffect(() => {
		me?.me && setUser(me.me)
		// if (authOrgUser?.organizations?.length === 1) {
		// 	setActiveOrg(me?.me?.organizations.find((org) => org.kindeId === authUser.organizations![0]) ?? null)
		// }
	}, [me, authUser])

	// Redirect to /account if authUser.name is null
	useEffect(() => {
		if (!authUser?.name) {
			navigate('/account')
		}
	}, [authUser, navigate])

	if (!user || isLoading) {
		return <FullPageLoader />
	}

	return (
		<MeContext.Provider
			value={{
				id: user?.id,
				isManager: user?.isManager,
				isAdmin: user?.isAdmin,
				email: user?.email,
				firstName: user?.firstName,
				lastName: user?.lastName,
				organizations: user?.organizations ?? [],
				organizationRoles: roleArray,
				userPosition: user?.userPosition,
			}}
		>
			{authUser?.name ? (
				<Routes>
					{(roleArray.includes('admin') || roleArray.includes('billing')) && (
						<Route
							path="/billing"
							element={
								<>
									<PageTitle title="Billing | NextPeak" />
									<Billing />
								</>
							}
						/>
					)}
					{roleArray.includes('employee') && (
						<Route
							path="/assessments"
							element={
								<>
									<PageTitle title="Assessments | NextPeak" />
									<Assessments />
								</>
							}
						/>
					)}
					{roleArray.includes('employee') && (
						<Route
							path="/assessments/:assessmentId"
							element={
								<div>
									<PageTitle title="Assessments | NextPeak" />
									<AssessmentDetail />
								</div>
							}
						/>
					)}
					{roleArray.includes('employee') && (
						<Route
							path="/assessments/:assessmentId/responses/:responseId"
							element={
								<div>
									<PageTitle title="Assessments | NextPeak" />
									<AssessmentSurvey />
								</div>
							}
						/>
					)}
					<Route
						path="/account"
						element={
							<>
								<PageTitle title="Account | NextPeak" />
								<Account />
							</>
						}
					/>
					{roleArray.includes('employee') && (
						<Route
							path="/employees"
							element={
								<>
									<PageTitle title="Surveys | Surveyjs" />
									<Employees />
								</>
							}
						/>
					)}
					{roleArray.includes('employee') && (
						<Route
							path="/employees/:employeeId"
							element={
								<>
									<PageTitle title="Surveys | Surveyjs" />
									<EmployeeDetail />
								</>
							}
						/>
					)}
					{roleArray.includes('employee') && (
						<Route
							path="/organization/settings"
							element={
								<>
									<PageTitle title="Surveys | Surveyjs" />
									<OrganizationSettings />
								</>
							}
						/>
					)}
					{roleArray.includes('employee') && (
						<Route
							path="/organization/graph"
							element={
								<>
									<PageTitle title="Surveys | Surveyjs" />
									<OrganizationOverview />
								</>
							}
						/>
					)}
					{roleArray.includes('employee') && (
						<Route
							path="/organization/overview"
							element={
								<>
									<PageTitle title="Surveys | Surveyjs" />
									<EmployeesList />
								</>
							}
						/>
					)}
					<Route
						path="/devkit"
						element={
							<>
								<PageTitle title="Account | NextPeak" />
								<DevKit />
							</>
						}
					/>
					<Route
						index
						element={
							<>
								<PageTitle title="Admin | NextPeak" />
								<Home />
							</>
						}
					/>
				</Routes>
			) : (
				<Routes>
					<Route
						path="/account"
						element={
							<>
								<PageTitle title="Account | NextPeak" />
								<Account />
							</>
						}
					/>
					<Route
						index
						element={
							<>
								<PageTitle title="Admin | NextPeak" />
								<Home />
							</>
						}
					/>
				</Routes>
			)}
		</MeContext.Provider>
	)
}

export default App
