import React, { useState, useEffect } from 'react'
import OrganizationChart from '../../pages/Organization/OrganizationChart'
import { getMe } from '../../MeProvider.ts'
import SelectGroupGeneric from '../../components/Forms/SelectGroup/SelectGroupGeneric'
import CustomDatePicker from '../../components/Forms/DatePicker/CustomDatePicker'
//import { useGraphQLClient } from '../../useGraphQLClient.ts';
//import { useGetUsersByOrganizationQuery, useGetInvitesByOrganizationQuery, useGetPositionsByOrganizationQuery } from '../../generated/graphql.ts';
import Loader from '../../common/Loader'
import profileImage from '../../images/user/profile.png'
import { useGetOrganizationUsers } from '../../http/hooks.ts'
import { useRestClient } from '../../useRestClient.ts'
import { useOrgAuth } from '../../OrgAuthProvider.ts'

const Information = () => {
	//const graphQlClient = useGraphQLClient();
	const restClient = useRestClient()
	const { authOrgUser } = useOrgAuth()
	const [membersCount, setMembersCount] = useState([])
	const [loading, setLoading] = useState(true)

	const me = getMe()
	const isAdmin = me.organizationRoles.includes('admin')
	const orgId = me.organizations[0].kindeId
	const orgDBId = me.organizations[0].id

	const {
		data: orgUsers,
		isLoading: orgUsersLoading,
		error: orgUsersError,
		refetch: userRefetch,
	} = useGetOrganizationUsers(restClient, authOrgUser.internalOrgId)

	useEffect(() => {
		// Check if all required data is available
		if (orgUsersLoading) {
			setLoading(true)
			return
		} else {
			setLoading(false)
			setMembersCount(orgUsers.length)
		}
	}, [orgUsers, orgUsersError, orgUsersLoading])

	if (loading) {
		return <Loader /> // Show the loader while data is being processed
	}

	return (
		<div className="grid grid-cols-5 gap-8">
			<div className="col-span-5 xl:col-span-3">
				<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
					<div className="p-7">
						<form action="#">
							<div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
								<div className="w-full sm:w-1/2">
									<label className="mb-3 block text-sm font-medium text-black dark:text-white" htmlFor="lastName">
										Organization Name
									</label>
									<input
										className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
										type="text"
										name="lastName"
										id="lastName"
										placeholder={me.organizations[0].name}
									/>
								</div>
								<div className="mb-5.5">
									<label className="mb-3 block text-sm font-medium text-black dark:text-white" htmlFor="emailAddress">
										Number of members
									</label>
									<div className="relative flex items-center">
										<span className="mt-2 text-4xl font-bold text-grey-700 dark:text-white" id="emailAddress">
											{membersCount}
										</span>
									</div>
								</div>
							</div>

							<SelectGroupGeneric header="Assessment Period" options={['Quarterly', 'Trimester', 'Bi-yearly', 'Yearly']} />
							<CustomDatePicker header={'Assessment Time'} />
							<SelectGroupGeneric header="Assessment Window" options={['7 Days', '3 Days', '2 Days', '1 Day']} />
							{/*<div className="mb-5.5"><DarkModeSwitcher/></div>*/}
							<div className="flex justify-end gap-4.5">
								<button
									className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
									type="submit"
								>
									Cancel
								</button>
								<button
									className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
									type="submit"
								>
									Save
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Information
