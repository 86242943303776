import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { Link, useParams } from 'react-router-dom'
import { useCreateAssessmentMutation, User, useUserAssessmentsQuery } from '../../generated/graphql.ts'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import Loader from '../../common/Loader'
import { AssessmentsTable } from '../Assessments'
import { useCallback, useState } from 'react'
import { getMe } from '../../MeProvider.ts'
import { useOrgAuth } from '../../OrgAuthProvider.ts'
import { useRestClient } from '../../useRestClient.ts'
import { useGetOrganizationUsers, useGetUser, useGetUserOrganizationRoles } from '../../http/hooks.ts'
import { toast } from 'react-toastify'
import { EmployeesTable } from './index.tsx'

const SubordinatesTableView = ({ employeeId }: { employeeId: string }) => {
	const {
		authOrgUser: { internalOrgId },
	} = useOrgAuth()
	const restClient = useRestClient()
	const { data: employees } = useGetOrganizationUsers(restClient, internalOrgId, employeeId)

	return <>{employees && <EmployeesTable employees={employees as User[]} />}</>
}

const AssessmentsTableView = ({ employeeId, employeeManagerId }: { employeeId: string; employeeManagerId: string }) => {
	const {
		authOrgUser: { internalOrgId },
	} = useOrgAuth()
	const me = getMe()

	const isAuthUserEmployeeManager = me.id === employeeManagerId
	const isAuthUserEmployee = me.id === employeeId

	const graphQlClient = useGraphQLClient()
	const { data, isLoading, isSuccess } = useUserAssessmentsQuery(
		graphQlClient,
		{
			userId: employeeId,
			userIsManager: false,
		},
		{
			enabled: isAuthUserEmployeeManager || isAuthUserEmployee,
		}
	)

	const mutation = useCreateAssessmentMutation(graphQlClient)

	const handleSubmit = useCallback(async () => {
		try {
			if (!isAuthUserEmployeeManager) {
				toast.error('You are not allowed to create assessments for this user')
				return
			}
			const data = { employeeUserId: employeeId, managerUserId: employeeManagerId!, organizationId: internalOrgId }
			const response = await mutation.mutateAsync(data)

			console.log(response)
			toast.success('Assessment created')
			// @ts-ignore
			queryClient.invalidateQueries(['UserAssessments', { userId: employeeId, userIsManager: false }])
		} catch (e) {
			toast.error('Failed to create assessment')
		}
	}, [mutation])

	if (isLoading && !isSuccess) {
		return <Loader />
	}

	return (
		<>
			{isAuthUserEmployeeManager && (
				<button
					onClick={handleSubmit}
					className="inline-flex items-center justify-center rounded-md bg-meta-3 px-6 py-2 text-center font-medium text-white hover:bg-opacity-90"
				>
					Create assessment
				</button>
			)}
			{(isAuthUserEmployeeManager || isAuthUserEmployee) && <AssessmentsTable assessments={data?.userAssessments ?? []} />}
			{!(isAuthUserEmployeeManager || isAuthUserEmployee) && <div>You are not allowed to see anything here</div>}
		</>
	)
}

const TabbedTableView = ({
	employeeId,
	employeeManagerId,
	roles,
}: {
	employeeId: string
	employeeManagerId: string
	roles: { name: string; id: string }[]
}) => {
	const [openTab, setOpenTab] = useState(1)

	const activeClasses = 'text-primary border-primary'
	const inactiveClasses = 'border-transparent'

	const isUserManager = roles.some((role) => role.name === 'manager')

	return (
		<div>
			<div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
				<Link
					to="#"
					className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
						openTab === 1 ? activeClasses : inactiveClasses
					}`}
					onClick={() => setOpenTab(1)}
				>
					Assessments
				</Link>
				{isUserManager && (
					<Link
						to="#"
						className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
							openTab === 2 ? activeClasses : inactiveClasses
						}`}
						onClick={() => setOpenTab(2)}
					>
						Subordinates
					</Link>
				)}
			</div>
			<div>
				<div className={`${openTab === 1 ? 'block' : 'hidden'}`}>
					<AssessmentsTableView employeeId={employeeId} employeeManagerId={employeeManagerId} />
				</div>
				{isUserManager && (
					<div className={`${openTab === 2 ? 'block' : 'hidden'}`}>
						<SubordinatesTableView employeeId={employeeId} />
					</div>
				)}
			</div>
		</div>
	)
}

const EmployeeDetail = () => {
	const { employeeId } = useParams()

	if (!employeeId || employeeId === 'undefined') {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>How is this even possible</div>
				</div>
			</DefaultLayout>
		)
	}

	const restClient = useRestClient()
	const { data: employee, isLoading } = useGetUser(restClient, employeeId)
	const { data: roles, isLoading: isLoadingRoles } = useGetUserOrganizationRoles(restClient, employeeId)

	const isUserLoading = isLoading || isLoadingRoles

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Employee" />
			{isUserLoading && <Loader />}
			{!isUserLoading && (
				<>
					<div className="grid grid-cols-12 gap-8">
						<div className="rounded-xl bg-white shadow-default dark:border-strokedark dark:bg-boxdark col-span-12 p-4 flex flex-col items-center">
							{employee && (
								<>
									<div className="relative z-30 mx-auto h-20 w-full max-w-20 bg-white/20 overflow-hidden flex justify-center items-center rounded-full mt-7">
										{employee.logTo.avatar && <img src={employee.logTo.avatar} alt="profile" />}
										{!employee.logTo?.avatar && (
											<img
												src="https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg"
												alt="profile"
											/>
										)}
									</div>
									<h3 className="text-2xl mb-2 mt-4 font-semibold text-black">{employee.firstName + ' ' + employee.lastName}</h3>
									{/*<p className="text-xl mb-2 mt-4">{employee.userPosition?.position.title}</p>*/}
									{/*<p className="text-xl mb-2">{me.organizations[0].name}</p>*/}
								</>
							)}
						</div>
					</div>
					<div className="flex flex-col gap-10 mt-4">
						{employee && roles && <TabbedTableView employeeId={employeeId} employeeManagerId={employee.managerId} roles={roles} />}
					</div>
				</>
			)}
		</DefaultLayout>
	)
}

export default EmployeeDetail
