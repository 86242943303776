import { HttpClient } from '../../httpClient'

export interface ICreateOrgInviteRequest {
	email: string
	managerId: string
	position: string
	level: string
	roles: string[]
}

export const createOrganizationInvite = async (client: HttpClient, invite: ICreateOrgInviteRequest) => {
	const response = await client.post(`/api/invites/create`, invite)
	return response.data
}
