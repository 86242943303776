import { useCallback, useState } from 'react'
import DefaultLayout from '../../layout/DefaultLayout.tsx'
import Card from '../../components/Card.tsx'
import { useOrgAuth } from '../../OrgAuthProvider.ts'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import { useGetPositionsQuery } from '../../generated/graphql.ts'
import { useRestClient } from '../../useRestClient.ts'
import { useCreateOrgInvite, useGetOrganizationRoles, useGetOrganizationUsers } from '../../http/hooks.ts'
import Loader from '../../common/Loader'
import Select from '../../components/Select.tsx'

const DevKit = () => {
	const graphQlClient = useGraphQLClient()
	const restClient = useRestClient()
	const { authOrgUser } = useOrgAuth()

	const [email, setEmail] = useState<string>('')
	const [managerId, setManagerId] = useState<string>('')
	const [positionId, setPositionId] = useState<string>('')

	const mutation = useCreateOrgInvite(restClient)

	const handleSubmit = useCallback(() => {
		try {
			mutation.mutate({ email, managerId, positionId, roles: ['7d5nlgoz9cp5itpn5k2xu'] })
		} catch (error) {
			console.error('Create of org Invite failed:', error)
		}
	}, [mutation])

	const { data: orgUsers, isSuccess: isUsersSuccess } = useGetOrganizationUsers(restClient, authOrgUser.internalOrgId)
	const { data: roles, isSuccess: isRolesSuccess } = useGetOrganizationRoles(restClient)
	const { data: positionsData, isSuccess: isPositionsSuccess } = useGetPositionsQuery(graphQlClient)

	if (!isUsersSuccess || !isPositionsSuccess || !isRolesSuccess) {
		return <Loader />
	}

	console.log(roles)

	return (
		<DefaultLayout>
			<div className="flex flex-col gap-10">
				<Card>
					<h2 className="text-center mb-9 text-2xl font-bold text-black dark:text-white">Invite user</h2>
					<div className="flex flex-col gap-5.5 p-6.5">
						<input
							type="email"
							className="w-full border border-stroke rounded py-3.5 px-4 focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></input>
						<Select
							options={orgUsers.map((user) => ({ value: user.id, text: user.name }))}
							name="Select manager"
							selected={managerId}
							onSelect={setManagerId}
						/>
						<Select
							options={positionsData.positions.map((position) => ({ value: position.id, text: position.title }))}
							name="Select position"
							selected={positionId}
							onSelect={setPositionId}
						/>
						<button onClick={handleSubmit} className="w-full bg-primary text-white rounded py-3.5">
							Create assessment
						</button>
					</div>
				</Card>
			</div>
		</DefaultLayout>
	)
}

export default DevKit
